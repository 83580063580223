import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 404.000000 316.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">



<path d="M472 4670 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M591 4654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M552 4640 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M453 4595 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z"/>
<path d="M550 4599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M476 4582 c-3 -5 8 -6 25 -4 16 2 29 6 29 8 0 8 -49 4 -54 -4z"/>
<path d="M520 4490 c11 -21 35 -27 44 -11 4 5 -3 7 -14 4 -14 -4 -20 0 -20 11
0 9 -5 16 -10 16 -7 0 -7 -6 0 -20z"/>
<path d="M444 4485 c15 -8 33 -15 40 -15 6 0 -2 7 -18 15 -15 8 -33 15 -40 15
-6 0 2 -7 18 -15z"/>
<path d="M375 4480 c3 -5 16 -10 28 -9 21 0 21 1 2 9 -28 12 -37 12 -30 0z"/>
<path d="M581 4461 c1 -17 3 -20 6 -8 2 9 13 17 23 17 31 0 30 15 -1 16 -25 1
-29 -2 -28 -25z"/>
<path d="M910 2315 l0 -277 178 4 c172 3 178 4 223 30 47 28 79 75 79 117 0
46 -32 101 -72 125 -35 21 -38 25 -21 30 34 11 64 52 70 94 7 54 -19 96 -77
125 -40 19 -64 22 -212 25 l-168 4 0 -277z m310 145 c15 -23 12 -51 -7 -72
-13 -14 -31 -18 -90 -18 l-73 0 0 54 c0 49 2 55 23 58 45 6 138 -8 147 -22z
m8 -210 c27 -26 28 -54 2 -80 -17 -17 -33 -20 -100 -20 l-80 0 0 60 0 60 78 0
c65 0 82 -3 100 -20z"/>
<path d="M1470 2315 l0 -275 220 0 220 0 0 55 0 55 -150 0 -150 0 0 55 0 55
130 0 130 0 0 55 0 55 -130 0 -130 0 0 55 0 55 145 0 145 0 0 55 0 55 -215 0
-215 0 0 -275z"/>
<path d="M2000 2315 l0 -275 220 0 220 0 0 55 0 55 -150 0 -150 0 0 55 0 55
130 0 130 0 0 55 0 55 -130 0 -130 0 0 55 0 55 145 0 145 0 0 55 0 55 -215 0
-215 0 0 -275z"/>
<path d="M2530 2315 l0 -275 65 0 65 0 0 64 c0 95 1 96 79 96 162 0 243 66
243 199 0 66 -17 107 -61 144 -42 35 -110 47 -261 47 l-130 0 0 -275z m283
154 c25 -11 47 -47 47 -74 0 -27 -22 -63 -47 -74 -13 -6 -53 -11 -88 -11 l-65
0 0 69 c0 99 2 101 71 101 32 0 69 -5 82 -11z"/>
<path d="M3216 2528 c-55 -125 -206 -480 -206 -484 0 -2 30 -4 68 -4 l67 0 20
53 21 52 113 3 c70 2 118 -1 125 -8 6 -6 17 -29 26 -53 l16 -42 72 -3 c39 -2
72 0 72 3 0 4 -13 37 -29 73 -16 37 -52 123 -81 192 -29 69 -67 159 -84 200
l-32 75 -70 3 -70 3 -28 -63z m136 -183 l36 -95 -79 0 c-43 0 -79 2 -79 5 0
13 73 185 79 185 3 0 23 -43 43 -95z"/>
<path d="M3629 2553 c10 -21 55 -105 100 -187 l81 -149 0 -88 0 -89 70 0 70 0
0 91 0 91 77 146 c42 81 85 161 95 177 10 17 18 33 18 38 0 4 -30 7 -67 7
l-68 -1 -20 -42 c-46 -93 -99 -187 -106 -187 -4 0 -36 52 -71 115 l-63 115
-68 0 -67 0 19 -37z"/>
<path d="M1467 1732 c6 -4 13 -21 16 -37 l4 -30 2 32 c0 21 7 33 19 36 9 3 1
5 -18 6 -19 0 -30 -3 -23 -7z"/>
<path d="M1573 1720 c-1 -16 4 -20 25 -19 l27 1 -25 8 -25 7 25 11 25 11 -25
0 c-19 1 -26 -4 -27 -19z"/>
<path d="M1790 1700 c0 -37 2 -40 28 -39 23 2 24 2 5 6 -27 5 -31 30 -5 36 16
4 16 5 0 6 -26 2 -22 19 5 24 19 4 18 4 -5 6 -26 1 -28 -2 -28 -39z"/>
<path d="M1908 1729 c-10 -5 -18 -16 -18 -24 0 -19 28 -45 48 -45 12 0 9 4 -8
13 -14 7 -25 19 -25 27 0 8 11 20 25 27 14 7 18 13 10 13 -8 -1 -23 -5 -32
-11z"/>
<path d="M2022 1728 c-25 -25 -6 -68 30 -68 9 0 22 9 28 19 13 25 13 27 -6 45
-19 19 -36 20 -52 4z m48 -14 c11 -12 11 -18 -2 -30 -18 -18 -48 -8 -48 16 0
16 11 27 31 29 4 1 12 -6 19 -15z"/>
<path d="M2151 1705 c1 -40 1 -40 11 -12 7 21 7 32 -1 40 -8 8 -11 0 -10 -28z"/>
<path d="M2204 1729 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z"/>
<path d="M2291 1698 c2 -34 3 -37 6 -13 l5 30 13 -24 13 -24 13 24 13 24 7
-25 c7 -23 7 -23 8 7 1 42 -13 49 -28 15 l-13 -27 -14 28 c-20 37 -24 34 -23
-15z"/>
<path d="M2431 1708 c-1 -29 3 -38 20 -43 29 -9 41 6 36 46 l-4 34 -1 -38 c-2
-31 -6 -38 -19 -35 -12 2 -20 16 -24 38 l-6 35 -2 -37z"/>
<path d="M2561 1705 c1 -40 1 -40 10 -10 5 18 5 33 -1 39 -7 7 -10 -4 -9 -29z"/>
<path d="M2610 1714 c0 -22 -2 -25 -15 -14 -23 19 -18 -4 5 -25 20 -18 20 -17
20 23 0 23 -2 42 -5 42 -3 0 -5 -12 -5 -26z"/>
<path d="M2683 1705 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M2766 1724 c-19 -18 -19 -20 -6 -45 11 -20 35 -25 45 -9 3 5 0 7 -8
4 -8 -3 -20 2 -27 11 -10 12 -10 18 0 30 7 9 19 14 27 11 8 -3 11 -1 8 4 -9
14 -21 12 -39 -6z"/>
<path d="M2884 1705 c-8 -21 -13 -40 -10 -43 3 -3 6 0 6 6 0 7 11 12 25 12 14
0 25 -5 25 -12 0 -6 2 -9 5 -6 13 13 -7 35 -27 30 -15 -4 -19 -1 -16 9 3 8 11
13 17 12 14 -4 15 14 1 22 -6 4 -17 -10 -26 -30z"/>
<path d="M2997 1732 c6 -4 13 -21 16 -37 l4 -30 2 32 c0 21 7 33 19 36 9 3 1
5 -18 6 -19 0 -30 -3 -23 -7z"/>
<path d="M3103 1705 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M3188 1729 c-26 -15 -22 -48 7 -61 43 -19 73 23 39 56 -18 19 -23 19
-46 5z m42 -15 c11 -12 11 -18 -2 -30 -18 -18 -48 -8 -48 16 0 16 11 27 31 29
4 1 12 -6 19 -15z"/>
<path d="M3310 1721 c0 -13 3 -22 6 -18 3 3 17 -6 30 -21 l23 -27 0 45 c-1 38
-2 41 -8 15 l-6 -29 -22 29 c-22 30 -22 30 -23 6z"/>
<path d="M3434 1728 c-4 -7 2 -18 14 -26 28 -19 28 -36 1 -29 -16 4 -19 3 -11
-5 13 -13 42 -3 42 15 0 8 -10 19 -22 24 l-23 11 25 10 c24 10 24 11 3 11 -12
1 -25 -4 -29 -11z"/>
<path d="M1692 1698 c-2 -34 1 -38 21 -37 15 1 17 3 5 6 -10 2 -18 17 -21 36
-4 31 -4 31 -5 -5z"/>
<path d="M2172 1688 c2 -5 8 -9 13 -9 5 0 11 4 13 9 1 5 -4 9 -13 9 -9 0 -14
-4 -13 -9z"/>
<path d="M1573 1675 c0 -10 10 -15 31 -14 21 1 25 3 11 6 -11 3 -25 9 -31 14
-7 5 -11 3 -11 -6z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
